import React from 'react';
import styles from './style.module.scss';
import Image from 'next/image';
import Link from 'next/link';

interface EssentialFurnitureProps {
  data: any;
}

const EssentialFurniture: React.FC<EssentialFurnitureProps> = ({ data }) => {
  return (
    <>
      <section className={styles.essentftursection}>
        <div className="container-fluid">
          <div className={styles.essentfturinner}>
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.essentfturheading}>
                  <h2>{data?.title}</h2>
                  <p>{data?.subtitle}</p>
                </div>
              </div>
            </div>
            <div className={styles.essentfturcontent}>
              <div className="wdRow wdgx-3">
                {data?.items?.map((product: any, index: number) => (
                  <div
                    className="wdCol-lg-3 wdCol-md-3 wdCol-sm-6 wdCol-12"
                    key={index}
                  >
                    <Link
                      href={product.category}
                      className={styles.essentfturcard}
                    >
                      <div className={styles.essentfturimg}>
                        <Image
                          src={product.imageSrc}
                          alt={product.alt}
                          title={product.name}
                          loading="lazy"
                          quality={100}
                          width={445}
                          height={425}
                        />
                      </div>
                      <div className={styles.essentfturtext}>
                        <h4>{product.name}</h4>
                        <p>{product.priceRange}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EssentialFurniture;
